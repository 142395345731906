header {
  height: 100vh;
  padding-top: 7rem;
  overflow: hidden;
}

.header_container {
  text-align: center;
  height: 100%;
  position: relative;
}

/* ==================== CTA ============== */
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/* ==================== SOCIALS ============== */

.header_socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
}

.header_socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}
/* ==================== ME ============== */

.me {
  /* background: linear-gradient(var(--color-primary), transparent); */
  width: 24rem;
  height: 30rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 4rem;
  /* border-radius: 12rem 12rem 0 0; */
  overflow: hidden;
  padding: 5rem 1.5rem 1.5rem 1.5rem;
}
/* ==================== SCROLL DOWN ============== */

.scroll_down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
}

/* ==================MEDIA QUERIES (MEDIUM DEVICES)==================== */

@media screen and (max-width: 1024px) {
  header {
    height: 68vh;
    overflow: visible;
  }
  .about-btn {
    float: unset;
  }
}

/* ==================MEDIA QUERIES (SMALL DEVICES)==================== */

@media screen and (max-width: 600px) {
  header {
    height: 100vh;
    overflow: visible;
  }
  .header_socials,
  .scroll_down {
    display: none;
  }
  .about_cards {
    padding: 0 1.5rem 0 1.5rem;
  }
  .me {
    padding: 0 1.5rem 0 1.5rem;
  }
}
